
import { Vue, Component } from 'vue-property-decorator';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import EmailInput from '@/components/inputs/EmailInput.vue';

import { Route, RawLocation } from 'vue-router';
import router from '@/router';
import fb from '@/store/gobi-firestore';
import { TypicalResponse, EditState } from '@/store/models.def';
import {
	PasswordStrength,
	passwordStrengthRequirement,
} from '@/components/inputs/password.def';

import firebase from 'firebase/app';
import continueToUrl from '@/helpers/continueToUrl';

let auth: firebase.auth.Auth;

@Component({
	components: {
		PasswordInput,
		EmailInput,
	},
})
export default class AuthAction extends Vue {
	public password = '';
	public repeatPassword = '';
	public validatePasswordNow = false;
	public passwordStrength = PasswordStrength.Medium;
	public savePasswordError: string = '';
	public savePasswordSuccess: string = '';
	public savePasswordState: EditState = 'active';

	public email = '';
	public validateEmailNow = false;
	public saveEmailError: string = '';
	public saveEmailSuccess: string = '';
	public saveEmailState: EditState = 'active';

	public title = 'Welcome';
	public content = 'Please wait a moment...';
	public showPage: 'general' | 'signIn' | 'resetPassword' = 'general';

	public isSigningIn = false;
	public isResettingPassword = false;

	public signInLink = '';

	public continueUrl = '/';
	public actionCode = '';
	public mode = '';

	public get savePasswordLabel() {
		const labels = {
			active: 'Save',
			sending: 'Saving...',
			done: 'Saved',
		};
		return labels[this.savePasswordState];
	}
	public get saveEmailLabel() {
		const labels = {
			active: 'Sign In',
			sending: 'Signing In...',
			done: 'Signed In',
		};
		return labels[this.saveEmailState];
	}

	get passwordNotSame() {
		if (!this.validatePasswordNow) {
			return false;
		}
		return this.password !== this.repeatPassword;
	}
	get passwordRequirement() {
		return this.passwordNotSame
			? 'Two passwords do not match.'
			: passwordStrengthRequirement[this.passwordStrength];
	}

	public created() {
		auth = fb.auth!;
		(window as any).showError = this.showError;

		const refreshCountdown = setTimeout(() => {
			location.reload();
		}, 10000);

		const query = this.$route.query;
		let mode: string = '';
		let actionCode: string = '';

		if (query.continueUrl) {
			this.continueUrl = query.continueUrl as string;
		}
		if (query.oobCode) {
			actionCode = this.actionCode = query.oobCode as string;
		}
		if (query.mode) {
			mode = this.mode = query.mode as string;
		}

		switch (mode) {
			case 'resetPassword':
				clearTimeout(refreshCountdown);
				// Display reset password handler and UI.
				this.handleResetPassword(actionCode);
				break;
			case 'recoverEmail':
				clearTimeout(refreshCountdown);
				// Display email recovery handler and UI.
				this.handleRecoverEmail(actionCode);
				break;
			case 'verifyEmail':
				clearTimeout(refreshCountdown);
				// Display email verification handler and UI.
				this.handleVerifyEmail(actionCode);
				break;
			case 'signIn':
				clearTimeout(refreshCountdown);
				// Display email verification handler and UI.
				this.handleSignIn();
				break;
			default:
				clearTimeout(refreshCountdown);
				this.invalidPage();
		}
	}
	public invalidPage() {
		this.title = `<i class="fas fa-times-circle fa-fw text-danger"></i>  Invalid Page`;
		this.content = `The URL provided is incorrect. Leaving page...`;
		this.showPage = 'general';
		this.continuePage();
	}
	public continuePage(delay: number = 4000) {
		setTimeout(() => {
			continueToUrl(this.continueUrl);
		}, delay);
	}
	public async showError(title: string, message: string) {
		const h = this.$createElement;
		const titleVNode = h('div', {
			domProps: {
				innerHTML: `<i class="fas fa-exclamation-triangle fa-fw text-warning"></i>  <b>${title}</b>`,
			},
		});
		await this.$bvModal.msgBoxOk(message, {
			title: [titleVNode],
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			headerClass: 'p-2 border-bottom-0',
			footerClass: 'p-2 border-top-0',
		});
		this.continuePage(0);
	}
	public async handleSignIn() {
		this.signInLink = window.location.href;
		if (router.mode === 'hash') {
			this.signInLink =
				window.location.origin +
				'/' +
				this.$route.fullPath.replace(this.$route.path, '') +
				'#' +
				this.$route.path;
		}
		if (auth.isSignInWithEmailLink(this.signInLink)) {
			const email = window.localStorage.getItem('emailForSignIn');
			if (!email) {
				this.showPage = 'signIn';
			} else {
				const result = await this.signInWithEmailLink(email);
				if (result.success) {
					this.showPage = 'general';
					this.title = `<i class="fas fa-check-circle fa-fw text-success"></i>  Login Success!`;
					this.content = 'Logging In...';
					this.continuePage();
				} else {
					this.showPage = 'general';
					this.title = `<i class="fas fa-times-circle fa-fw text-danger"></i>  Invalid Sign In Link`;
					this.content = result.errorMessage!;
				}
			}
		} else {
			this.invalidPage();
		}
	}
	public async signInEmail() {
		if (this.saveEmailState !== 'active') {
			return;
		}
		this.validateEmailNow = true;
		if (this.email !== null) {
			this.saveEmailState = 'sending';
			const result = await this.signInWithEmailLink(this.email);
			if (result.success) {
				this.saveEmailState = 'done';
				this.saveEmailSuccess = 'Success! Logging in...';
				this.saveEmailError = '';
				this.continuePage();
			} else {
				this.saveEmailError = result.errorMessage!;
				this.saveEmailState = 'active';
			}
		}
	}
	public async signInWithEmailLink(email: string): Promise<TypicalResponse> {
		try {
			const result = await auth.signInWithEmailLink(email, this.signInLink);
			window.localStorage.removeItem('emailForSignIn');
			// You can access the new user via result.user
			// Additional user info profile not available via:
			// result.additionalUserInfo.profile == null
			// You can check if the user is new or existing:
			// result.additionalUserInfo.isNewUser
			return { success: true };
			this.continuePage();
		} catch (error) {
			// Some error occurred, you can inspect the code: error.code
			// Common errors could be invalid email and invalid or expired OTPs.
			return {
				success: false,
				error,
				errorCode: error.code,
				errorMessage: error.message,
			};
		}
	}
	public handleResetPassword(actionCode: string) {
		// Verify the password reset code is valid.
		auth
			.verifyPasswordResetCode(actionCode)
			.then((email) => {
				this.email = email;
				this.showPage = 'resetPassword';
			})
			.catch((error) => {
				this.showError('Error occured.', error.message);
			});
	}
	public async updatePassword() {
		if (this.savePasswordState !== 'active') {
			return;
		}
		this.validatePasswordNow = true;
		if (
			this.password !== null &&
			this.repeatPassword !== null &&
			this.passwordNotSame === false
		) {
			this.savePasswordState = 'sending';
			const result = await this.saveNewPassword(this.password);

			if (result.success) {
				this.savePasswordState = 'done';
				this.savePasswordSuccess = 'Success! Logging In...';
				this.savePasswordError = '';
				this.continuePage();
			} else {
				this.savePasswordError = result.errorMessage!;
				this.savePasswordState = 'active';
			}
		}
	}
	public async saveNewPassword(newPassword: string): Promise<TypicalResponse> {
		// Save the new password.
		try {
			const result = await auth.confirmPasswordReset(this.actionCode, newPassword);
			return { success: true };
		} catch (error) {
			// Error occurred during confirmation. The code might have expired or the
			// password is too weak.
			return {
				success: false,
				error,
				errorCode: error.code,
				errorMessage: error.message,
			};
		}
	}

	public async handleRecoverEmail(actionCode: string) {
		let restoredEmail: string | null | undefined = null;
		// Confirm the action code is valid.
		try {
			const info = await auth.checkActionCode(actionCode);

			restoredEmail = info.data.email;

			// Revert to the old email.
			await auth.applyActionCode(actionCode);
			// Account email reverted to restoredEmail

			this.title = `<i class="fas fa-check-circle fa-fw text-success"></i>  Account Email Restored!`;
			this.content = `Your account corresponding email has been restored.<br/>Logging in...`;
			this.showPage = 'general';
			this.continuePage();
		} catch (error) {
			// Invalid code.
			this.showError('Invalid Code', error.message);
		}
	}
	public handleVerifyEmail(actionCode: string) {
		// Try to apply the email verification code.
		let verifiedBefore = false;
		let applied = false;
		let email: string | null = null;

		const applyActionCode = async () => {
			try {
				const result = await auth.applyActionCode(actionCode);
				if (verifiedBefore) {
					this.continuePage();
				} else {
					await auth.signOut();
					this.continueUrl = `/login?continueUrl=${this.continueUrl}&${
						email ? '&email=' + email : ''
					}`;
					this.continuePage();
				}
			} catch (error) {
				this.showError('Invalid Code.', error.message);
			}
		};
		// if user email is not verified before this,
		// need to sign it out afterwards to refresh the token.
		if (auth.currentUser) {
			const user = auth.currentUser;
			applied = true;
			email = user.email;
			verifiedBefore = user.emailVerified;
			applyActionCode();
		} else {
			auth.onAuthStateChanged((user) => {
				if (applied) {
					return;
				}
				applied = true;
				if (user) {
					email = user.email;
					verifiedBefore = user.emailVerified;
				} else {
					verifiedBefore = false;
				}
				applyActionCode();
			});
		}
	}
}
