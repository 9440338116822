
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseInput from '@/components/inputs/BaseInput.vue';

import {
	PasswordStrength,
	passwordStrengthRegex,
	passwordStrengthRequirement,
} from '@/components/inputs/password.def';

@Component
export default class PasswordInput extends BaseInput {
	@Prop({ default: PasswordStrength.Weak })
	public readonly strength!: PasswordStrength;
	@Prop({ default: true }) public readonly showInvalid!: boolean;
	@Prop({ default: 'Password' }) public readonly placeholder!: string;

	public input: string = '';

	get requirement() {
		return passwordStrengthRequirement[this.strength];
	}

	public validate(value: string) {
		if (value === null) {
			return false;
		}
		return passwordStrengthRegex[this.strength].test(value);
	}
}
